<template>
    <div id="main">
        <div id="subnav">
            <div class="inner">
                <ul>
                    <li class="nli">
                        <h4><a href="/#/about">集团简介</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/organization">组织机构</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/honor">资质荣誉</a></h4>
                    </li>
                    <li class="nli">
                        <h4><a href="/#/about/culture">企业文化</a></h4>
                    </li>
                    <li class="nli"><h4><a href="/#/about/history">耀华历史</a></h4></li>
                    <li class="nli"><h4><a href="/#/about/subsidiary">子公司</a></h4></li>
                </ul>
            </div>
        </div>
        <div class="inner">
            <div id="content">
                <h5>组织机构</h5>
                <div class="content">
                    <div id="text">
                        <div style="text-align:center;">
                            <img src="@/../uploads/638d83632572c1.png" style="max-width: 100%; max-height: 500px; margin: 0 auto;" title="638d83632572c.png" alt="638d83632572c.png"><br>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
    </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Organization",
        data() {
            return {
                interval: 4000,
                items: [
                    {image: '5e156e89e6e27.jpg', title: 'Title 1', description: 'Description 1'}
                ],
                isFollowVisible: true // 控制是否显示关注部分，默认为显示
            }
        },
        mounted() {
            this.$emit('update-carousel', this.items);
        },
    }
</script>

<style>

</style>
